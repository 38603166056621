import Typewriter from 'typewriter-effect'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser'
import './home.css'

const Home = () => {
  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1twykyy', 'template_dvm4fge', form.current, 'EZ35BTbPD_XdNG61O')
      .then((result) => {
          console.log(result.text);
          console.log("message sent!");
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  return (
    <header id="header">
        <div className="container header__content">
            <h1>UPICK US FOR YOUR 
              <strong className='quick__edit'>
                <Typewriter 
                  options={{
                    autoStart: true,
                    loop: true,
                    delay: 50,
                    strings: ["PAINTING NEEDS", "PAINTING SERVICES", "INTERIOR PAINING JOBS", "EXTERIOR PAINTING JOBS", "RESIDENTIAL PAINTING", "HOUSE PAINTING JOBS", "COMMERCIAL PAINTING"]
                  }}
                />
              </strong>
            </h1>
            <p>Let us do the hard work for you. Get a free quote today!</p>
            <a href="#contact"><button className="btn">Contact Now</button></a>
        </div>
        <div className="container form__box">
            <h1>Free Estimate</h1>
            <p>Get a quick response within 24 hours</p>
            <form ref={form} onSubmit={sendEmail} className='form__content'>
                <label>Name*</label>
                <input type="text" name="user_name" placeholder="Enter Your Full Name" required autoComplete='name'/>
                <label>Email*</label>
                <input type="email" name="user_email" placeholder="Enter Your Email" required autoComplete='email'/>
                <label>Phone Number*</label>
                <input type="text" name="user_number" placeholder="Enter Your Phone Number" required autoComplete='tel'/>
                <button type="submit" className='btn' aria-label='Submit form'>GET ESTIMATE!</button>
            </form>
        </div>
    </header>
  )
}

export default Home