import Aos from 'aos'
import { useEffect } from 'react'
import React, { useRef } from 'react';
import emailjs from '@emailjs/browser'
import 'aos/dist/aos.css'
import './contact.css'

const Contact = () => {
  const form = useRef();
  
  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_1twykyy', 'template_dvm4fge', form.current, 'EZ35BTbPD_XdNG61O')
      .then((result) => {
          console.log(result.text);
          console.log("message sent!");
      }, (error) => {
          console.log(error.text);
      });
      e.target.reset()
  };

  useEffect(()=> {
    Aos.init({duration: 2000})
  }, []);
  return (
    <section id="contact">
        <div className="container contact__container">
            <div className="contact__content">
                <h3>What Are You Waiting For?</h3>
                <h2>Let's Connect!</h2>
                <p>We respond fast! Let's get in touch.</p>
            </div>
            <form ref={form} onSubmit={sendEmail} className='contact__form' data-aos="fade-up">
                <label>
                  <p>Name:</p>
                  <input type="text" name="user_name" placeholder="Your Full Name" className='contact__input' required autoComplete='name'/>
                </label>

                <label>
                  <p>Email Address</p>
                  <input type="email" name="user_email" placeholder="Enter your Email" className='contact__input' required autoComplete='email'/>
                </label>
                
                <label>
                  <p>Phone Number</p>
                  <input type="number" name="user_number" placeholder="Enter your Phone Number" className='contact__input' required autoComplete='tel'/>
                </label>

                <label>
                  <p>Message</p>
                  <textarea name="message" rows="10" placeholder="Describe your project" className='contact__textarea' required autoComplete='on'></textarea>
                </label>
                <button type='submit' className='btn ' aria-label='Submit form'>GET ESTIMATE!</button>
            </form>
        </div>
    </section>
  )
}

export default Contact